import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе Evergreen Legal Partners
			</title>
			<meta name={"description"} content={"Орієнтуючись у праві, сприяти правосуддю"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе Evergreen Legal Partners"} />
			<meta property={"og:description"} content={"Орієнтуючись у праві, сприяти правосуддю"} />
			<meta property={"og:image"} content={"https://voxipool.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxipool.com/img/4363463.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxipool.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxipool.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxipool.com/img/4363463.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxipool.com/img/4363463.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxipool.com/img/4363463.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://voxipool.com/img/3.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Про нас
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Компанія Evergreen Legal Partners була заснована на вірі в те, що кожен заслуговує на доступ до якісного юридичного представництва. Наш шлях розпочався з простої місії: демістифікувати юридичний процес та розширити можливості клієнтів. З роками ми розширили сферу наших послуг, охопивши широкий спектр правових питань, завжди зберігаючи при цьому відданість принципам доброчесності та адвокації.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Наша команда
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					В основі Evergreen Legal Partners лежить команда юристів, кожен з яких спеціалізується в різних галузях права. Ця різноманітність дозволяє нам підходити до кожної справи з комплексною перспективою, гарантуючи, що у вашій юридичній стратегії не залишиться жодного непоміченого каменя. Наші адвокати - не просто експерти у своїх галузях, вони є невпинними захисниками ваших прав.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://voxipool.com/img/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://voxipool.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Що нас відрізняє
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Інноваційні юридичні рішення: Ми використовуємо найсучасніші юридичні стратегії та технології, щоб ви завжди були на крок попереду.
					<br />
					<br />
					Відповідальність перед суспільством: Ми віримо у віддачу та відстоювання справедливості не лише для наших клієнтів, але й для суспільства в цілому.
					<br />
					<br />
					Індивідуальна правова допомога: Кожна справа розглядається з максимальною ретельністю, з урахуванням конкретних потреб та результатів, яких бажають наші клієнти.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Побудуйте свій юридичний шлях разом з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Вибір правильного юридичного партнера має вирішальне значення. В Evergreen Legal Partners ми не просто представляємо вас - ми приєднуємося до вас на вашому шляху. Разом ми можемо розробити юридичний шлях, який не лише вирішить ваші нагальні проблеми, але й захистить ваші майбутні інтереси. Дозвольте нам допомогти вам перетворити юридичні проблеми на можливості.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://voxipool.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});